import { Button, Flex, Heading, ModalV2, Text } from "sparta";
import loginicon from "~/assets/interaction-login-modal-icon.svg";
import AuthButtons from "../shared/AuthButtons";

interface LoginModalProps {
  description: string;
  open: boolean;
  toggleModal: (e: any) => void;
}

export function LoginModal({ open, toggleModal, description }: LoginModalProps) {
  return (
    <ModalV2.Root open={open} onOpenChange={toggleModal}>
      <ModalV2.Content>
        <ModalV2.Title className="loginModalTitle" style={{ textAlign: "center" }}>
          <Text>Log in</Text>
          <Text onClick={toggleModal}>X</Text>
        </ModalV2.Title>
        <Flex direction={"column"} align={"center"} gap={"32px"} style={{ alignSelf: "stretch" }}>
          {/* biome-ignore lint/a11y/useAltText: <explanation> */}
          <img style={{ width: "206px", height: "206px" }} src={`${loginicon}`} />
          <Heading>Login Required</Heading>
          <Text>{description}</Text>
        </Flex>
        <Flex direction="column" align="center" gap="16px" mt="4">
          <AuthButtons show />
          <Button onClick={toggleModal} variant="soft">
            Close
          </Button>
        </Flex>
      </ModalV2.Content>
    </ModalV2.Root>
  );
}
