import { useParams } from "@remix-run/react";
import { useNavigate } from "@remix-run/react";
import { Button, Flex } from "sparta";
import useResumeablePath from "~/utils/hooks/useResumeablePath";

interface IAuthButtons {
  show: boolean;
}

export default function AuthButtons({ show }: IAuthButtons) {
  const path = useResumeablePath();
  const params = useParams();

  const navigate = useNavigate();

  const loginNavigate = () => {
    const route: string = `/access/login?resume=${path}${params?.platform ? `&platform=${params.platform}` : ""}`;
    navigate(route);
  };

  const signUpNavigate = () => {
    window.location.href = "https://g2xchange.com/pricing";
  };

  return show ? (
    <Flex justify="end" align="center">
      <Button onClick={loginNavigate} style={{ width: 98 }} tabIndex={-1}>
        Log in
      </Button>
      <Button onClick={signUpNavigate} style={{ width: 98 }}>
        Sign up
      </Button>
    </Flex>
  ) : null;
}
